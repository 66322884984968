<template>
  <div class="fill-height">
    <v-container class="pa-6">
      <div class="text-h5 font-weight-bold mb-4">All Pools</div>

      <v-row class="mt-4">
        <v-col cols="12" md="6">
          <v-text-field
            hide-details
            outlined
            flat
            solo
            v-model="searchKey"
            prepend-inner-icon="mdi-magnify"
            placeholder="Search by Pool name, Token address, Token sympol"
            class="input"
            @keyup.enter="vm.fetchPools(1, searchKey)"
          />
        </v-col>
      </v-row>
      <v-card class="mt-8" outlined>
        <v-data-table
          :server-items-length="vm.totalCount"
          @update:page="vm.fetchPools($event)"
          :footer-props="{ itemsPerPageOptions: [10] }"
          :items="vm.allPools"
          :headers="headers"
          @click:row="showDetail"
          :loading="vm.loading"
        >
          <template v-slot:[`item.ratio`]="{ item }">
            1 {{ item.tradeToken }} = {{ item.pool.ratio }} {{ item.pool.tokenName }}
          </template>
          <template v-slot:[`item.progress`]="{ item }">
            <div
              class="d-flex align-center"
              :class="{
                'progress-desktop': $vuetify.breakpoint.mdAndUp,
                'progress-mobile': $vuetify.breakpoint.smAndDown,
              }"
            >
              <div style="white-space: nowrap; width: 50px">{{ item.progress | round(2) }}%</div>
              <v-progress-linear class="ml-2" height="12" rounded background-color="red" />
            </div>
          </template>
          <template v-slot:[`item.poolState`]="{ item }">
            <div class="d-flex align-center">
              <pool-state :state="item.poolState" />
            </div>
          </template>
        </v-data-table>
      </v-card>
      <div class="d-flex"></div>
    </v-container>
    <v-divider class="mt-7" />
    <company-footer />
  </div>
</template>

<script>
import { AllPoolsViewModel } from '../viewmodels/all-pools-viewmodel'
import { Observer } from 'mobx-vue'
import { Component, Vue, Provide } from 'vue-property-decorator'
import poolState from '@/modules/ido/components/pool-state.vue'

@Observer
@Component({
  components: {
    poolState,
  },
})
export default class AllPools extends Vue {
  @Provide() vm = new AllPoolsViewModel()

  searchKey = ''
  headers = [
    { text: 'Pool Name', value: 'pool.name', sortable: false },
    { text: 'Ratio', value: 'ratio', sortable: false },
    { text: 'Access', value: 'pool.accessType', sortable: false },
    { text: 'Progress', value: 'progress', sortable: false },
    { text: 'Status', value: 'poolState', sortable: false, align: 'start' },
  ]

  showDetail(item) {
    if (item.pool.address) {
      this.$router.push({ path: '/pool/' + item.pool.id })
    }
  }

  destroyed() {
    this.vm.destroy()
  }
}
</script>

<style scoped lang="scss">
.progress-mobile {
  width: 120px;
}
.progress-desktop {
  width: 200px;
}
</style>
